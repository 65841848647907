import { DropDownButton, DropDownContainer } from "@/components/keyPad.style";
import { IconButtonBox } from "@/components/shared/Button";
import Checkbox from "@/components/shared/Checkbox";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import ContactPicker from "@/components/shared/ContactPicker";
import {
  dropdownContainer,
  headerDropdown,
  sectionHeaderDropdownItemStyle,
} from "@/components/shared/Dropdown.style";
import InvitePopup from "@/components/shared/InvitePopup";
import NoResultFound from "@/components/shared/NoResultFound";
import { colors } from "@/styles/global.styles";
import { FilterTypeFields, isValidPhoneNumber } from "@/utils";
import { deleteConversation } from "@/utils/database";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { atoms } from "@/utils/helpers/atoms";
import { getLocalUser } from "@/utils/helpers/localstorage";
import {
  CapabilityType,
  VerseStatus,
  checkCapabilityOnLocalCache,
  checkCapabilityOnServer,
  useCapabilitiesOnCacheUpdate,
} from "@/utils/hooks/useCapabilities";
import { useChatbotDirectory } from "@/utils/hooks/useChatbotDirectory";
import { useContacts, useContactsSuspense } from "@/utils/hooks/useContacts";
import Conversation from "@/utils/messaging/conversation/Conversation";
import {
  ConversationMap,
  conversationsState,
  setSelectedConversationId,
} from "@/utils/messaging/conversation/ConversationState";
import {
  cleanPhoneNumber,
  isSamePhoneNumber,
} from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAtomValue, useSetAtom } from "jotai";
import { AnimatePresence } from "motion/react";
import {
  ReactElement,
  ReactNode,
  Suspense,
  lazy,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useTimeout } from "usehooks-ts";
import { useSnapshot } from "valtio";
import ChatScreen from "../../components/chatScreen/ChatScreen";
import { ThreeLinesLoaderBox } from "../../components/shared/Loaders/ThreeLines";
import SearchBar from "../../components/shared/SearchBar";
import SectionHeader from "../../components/shared/SectionHeader";
import { Box } from "../layout/Layout.style";
import {
  ConvoMenuItem,
  ConvoMenuSections,
  EditConvoMenu,
} from "./ConversationScreen.style";
import NewConversationScreen from "./NewConversationScreen";

const ListContainer = lazy(async () => ({
  default: (await import("../../components/shared/ListItem.style"))
    .ListContainer,
}));
const ConversationItem = lazy(() => import("./ConversationItem"));

let importsPreloaded = false;

function ConversationsList({
  children,
  searchConversationQuery,
}: {
  children: ReactNode[];
  searchConversationQuery: string;
}) {
  if (children.length === 0) {
    return (
      <NoResultFound
        placeHolder="No Conversations"
        searchQuery={searchConversationQuery}
      />
    );
  }

  // preload inner lazy loaded chat component
  if (!importsPreloaded) {
    import("../../components/chatScreen/ChatBox");
    importsPreloaded = true;
  }

  return (
    <ListContainer animateOrder items={children}>
      {children}
    </ListContainer>
  );
}

// used load the conversation dependencies (contacts and config) before rendering by using suspense.
// the actual values are called by synchronous code later when rendering
function PreloadConversationDeps() {
  // useAtomValue(contactsAtom);
  useContactsSuspense();
  useChatbotDirectory();
  useAtomValue(atoms.common.config);

  return null;
}

const LOG_PREFIX = `${ConversationsScreen.name}: `;

export default function ConversationsScreen() {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [selectedConversations, setSelectedConversations] = useState<
    Conversation[]
  >([]);
  const [newConversation, setNewConversation] = useState(false);
  const [selectedConversationsCheckboxes, setSelectedConversationsCheckboxes] =
    useState<string[]>([]);

  const [showConversation, setShowConversation] = useState(false);
  const [isConversationStarted, setIsConversationStarted] = useState(false);
  const [isShowInvitePopup, setIsShowInvitePopup] = useState<
    { invite: boolean; number?: string } | undefined
  >(undefined);
  const [showLoadingPhoneIcon, setShowLoadingPhoneIcon] = useState(false);
  const setDisplayContact = useSetAtom(atoms.contacts.displayContactId);
  const [showContactPicker, setShowContactPicker] = useState(false);
  const savedContacts = useContacts();
  const [contacts, setContacts] = useState<Array<WebGwContact>>(
    [] as WebGwContact[]
  );
  const {
    selectedConversationId,
    conversations: allConversations,
    isLoading: conversationsLoading,
  } = useSnapshot(conversationsState);

  // Filter out empty conversations
  const { conversations, conversationIdsArray } = useMemo(() => {
    const conversations: typeof allConversations = new Map();
    const conversationIdsArray: string[] = [];
    for (const [key, value] of allConversations.entries()) {
      if (value.messages.length > 0) {
        conversations.set(key, value);
        conversationIdsArray.push(key);
      }
    }

    return { conversations, conversationIdsArray };
  }, [allConversations]);

  const [showStartGroupConversation, setShowStartGroupConversation] =
    useState(false);
  const [addContactInputDefaultValue, setAddContactInputDefaultValue] =
    useState("");

  useTimeout(
    () => {
      if (newConversation) {
        setNewConversation(false);
      }
    },
    newConversation ? 250 : null
  );

  const handleShowConversation = () => {
    setIsConversationStarted(true);
    setShowConversation(true);
    setContacts([]);
  };

  const ongoingCheckCapsPhoneNumber = useRef<string | undefined>(undefined);

  const handleShowStartGroupConversation = (show: boolean) => {
    setShowStartGroupConversation(show);
  };

  const handleAddNumber = (phoneNumber: string) => {
    // Adding more than one contact case:
    // - Check first contact is not local user
    // - Check first contact caps, because conversation could have been initialized already with a non rcs contact (not known at that time)
    if (contacts && contacts.length === 1) {
      const localUserPhoneNumber = getLocalUser();
      const number = contacts[0].getMainPhoneNumber();

      if (isSamePhoneNumber(localUserPhoneNumber, number)) {
        toast("You must remove your own number from the list first", {
          duration: 5000,
          position: "bottom-right",
          style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
        });
        setShowLoadingPhoneIcon(false);
        return;
      }

      const capsFirstUser = checkCapabilityOnLocalCache(
        number,
        CapabilityType.MESSAGING
      );

      // First user does not have caps, avoid continuing
      // Note: here we dont need to refresh the caps as they were already triggered when adding the contact
      if (capsFirstUser.verseStatus === VerseStatus.NOT_INSTALLED) {
        setIsShowInvitePopup({ invite: true, number });
        setShowLoadingPhoneIcon(false);
        return;
      }
    }

    setShowLoadingPhoneIcon(true);

    checkAddNumberPossibility(phoneNumber)
      .then((res) => {
        const { number, result } = res;

        if (result === AddNumberResult.NO_CAPS) {
          setIsShowInvitePopup({ invite: true });
          setShowLoadingPhoneIcon(false);
          return;
        }

        // Current number being checked, we wait for the result in the useEffect above
        if (
          result === AddNumberResult.CHECKING_CAPS &&
          isSamePhoneNumber(phoneNumber, number)
        ) {
          return;
        }

        let errorMessage;
        switch (result) {
          case AddNumberResult.CANNOT_ADD_MYSELF:
            errorMessage = "You cannot add yourself as a participant";
            break;
          case AddNumberResult.ALREADY_ADDED:
            errorMessage =
              phoneNumber + " is already part of the list of numbers";
            break;
          case AddNumberResult.PHONE_NOT_VALID:
            errorMessage = phoneNumber + " is not valid";
            break;
          case AddNumberResult.CHECKING_CAPS:
            // We need to display the current phone number being checked if not current
            errorMessage =
              ongoingCheckCapsPhoneNumber.current +
              " is currently being verified, please hold";
            break;
        }

        if (errorMessage) {
          // Loading must stay while capabilities are being checked
          if (result !== AddNumberResult.CHECKING_CAPS) {
            setShowLoadingPhoneIcon(false);
          }
          if (result === AddNumberResult.PHONE_NOT_VALID) {
            return console.error(errorMessage);
          } else {
            return toast(errorMessage, {
              duration: 5000,
              position: "bottom-right",
              style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
            });
          }
        }

        addContact(phoneNumber);
        setAddContactInputDefaultValue("");
      })
      .catch(() => {
        setShowLoadingPhoneIcon(false);
      });
  };

  const checkAddNumberPossibility = async (
    phoneNumber: string
  ): Promise<{ number: string; result: AddNumberResult }> => {
    const localUserPhoneNumber = getLocalUser();
    const number = cleanPhoneNumber(phoneNumber);

    if (!isValidPhoneNumber(number)) {
      return { number, result: AddNumberResult.PHONE_NOT_VALID };
    }

    // Avoid duplicates
    if (
      contacts
        .flatMap((contact) => contact.getAllPhoneNumbers())
        .find((phoneNumber) => isSamePhoneNumber(phoneNumber, number))
    ) {
      return { number, result: AddNumberResult.ALREADY_ADDED };
    }

    // Check if some cached caps
    const singleConversation = contacts.length === 0;

    // When entering the first number we don't block and display the conversation view right away but still need to trigger fresh caps in case user adds more numbers to create a group (all numbers should be validated RCS from server in this case)
    if (singleConversation) {
      await checkCapabilityOnServer(number, CapabilityType.MESSAGING);

      return { number, result: AddNumberResult.SUCCESS };
    }
    // When adding more than one number (group chat), we need to validate caps from the server
    else {
      console.log(
        LOG_PREFIX,
        "refreshing caps for ",
        number,
        " before adding it"
      );

      // For single conversation we allow conversation with yourself
      if (isSamePhoneNumber(localUserPhoneNumber, number)) {
        return { number, result: AddNumberResult.CANNOT_ADD_MYSELF };
      }

      if (ongoingCheckCapsPhoneNumber.current) {
        return {
          number: ongoingCheckCapsPhoneNumber.current,
          result: AddNumberResult.CHECKING_CAPS,
        };
      }

      ongoingCheckCapsPhoneNumber.current = number;
      const res = await checkCapabilityOnServer(
        number,
        CapabilityType.MESSAGING
      );

      if (!res.capabilityExists) {
        return { number, result: AddNumberResult.CHECKING_CAPS };
      }

      ongoingCheckCapsPhoneNumber.current = undefined;

      return {
        number,
        result: res.hasCapability
          ? AddNumberResult.SUCCESS
          : AddNumberResult.NO_CAPS,
      };
    }
  };

  const addContact = (phoneNumber: string) => {
    setSearchContactQuery("");
    setShowLoadingPhoneIcon(false);
    const contact =
      savedContacts?.findWithNumber(phoneNumber) ||
      WebGwContact.fromPhoneNumber(phoneNumber);

    if (!contact) {
      console.log("No contact found for ", phoneNumber);
      return;
    }

    handleAddContact(contact);
  };

  /**
   * This is used to update the current caps status for an ongoing number that wants to be added to the conversation
   */
  useCapabilitiesOnCacheUpdate({
    condition: () => !!ongoingCheckCapsPhoneNumber.current,
    phoneNumber: () => ongoingCheckCapsPhoneNumber.current,
    capabilityType: CapabilityType.MESSAGING,
    callback: (hasCapability: boolean) => {
      const number = ongoingCheckCapsPhoneNumber.current;
      console.log(
        LOG_PREFIX,
        "received caps update for ",
        number,
        ", with capability ",
        hasCapability
      );

      if (number) {
        setShowLoadingPhoneIcon(false);

        if (!hasCapability) {
          setIsShowInvitePopup({ invite: true });
        } else {
          addContact(number);
        }
      }

      ongoingCheckCapsPhoneNumber.current = undefined;
    },
  });

  const handleSelectedContacts = (contactList?: WebGwContact[]) => {
    resetAddRemoveNumberState();

    if (!contactList) {
      return;
    }
    const localUserPhoneNumber = getLocalUser();

    // Remove duplicates and myself from original list
    contactList = contactList.reduce((acc, contact) => {
      if (
        acc.includes(contact) ||
        isSamePhoneNumber(localUserPhoneNumber, contact.getMainPhoneNumber())
      ) {
        return acc;
      }
      acc.push(contact);
      return acc;
    }, [] as WebGwContact[]);

    const contactsNotVerse: WebGwContact[] = [];

    // Strip out the non verse contact
    const contactsToAdd =
      contactList.length > 1
        ? contactList.filter((contact) => {
            const res = checkCapabilityOnLocalCache(
              contact.getMainPhoneNumber(),
              CapabilityType.MESSAGING
            );
            // When adding multiple contacts, we need to make sure they are all verse
            const hasVerse = res.verseStatus === VerseStatus.INSTALLED;

            if (!hasVerse) {
              contactsNotVerse.push(contact);
            }

            return hasVerse;
          })
        : contactList;

    setContacts(contactsToAdd);

    // Show single chat screen associated if there's only one contact
    const showConversation = contactsToAdd.length === 1;
    setShowConversation(showConversation);

    if (showConversation) {
      const selectedContact = contactsToAdd[0];
      const conversationId = Conversation.getOrCreate({
        phoneNumber: selectedContact.getMainPhoneNumber(),
        contactToLinkIfCreate: selectedContact,
      }).conversation.id;

      setSelectedConversationId(conversationId);
    }
  };

  const resetAddRemoveNumberState = () => {
    ongoingCheckCapsPhoneNumber.current = undefined;
  };

  const handleAddContact = (contact: WebGwContact) => {
    resetAddRemoveNumberState();

    // Avoid duplicates
    if (contacts.map((contact) => contact.id).includes(contact.id)) {
      return;
    }

    setContacts((prev) => [...prev, contact]);

    // 1 contact, show single chat screen associated
    const showConversation = contacts.length == 0;
    setShowConversation(showConversation);

    if (showConversation) {
      // We use the phone number that has the international format on it
      const conversationId = Conversation.getOrCreate({
        phoneNumber: contact.userInputNumber?.startsWith("+")
          ? contact.userInputNumber
          : contact.getMainPhoneNumber(),
        contactToLinkIfCreate: contact,
      }).conversation.id;

      setSelectedConversationId(conversationId);
    }
  };

  const handleRemoveContact = (
    contactId: string,
    currentAddContactInputValue?: string
  ) => {
    resetAddRemoveNumberState();
    const contactToRemove = contacts.findIndex(
      (contact) => contact.id === contactId
    );
    contacts.splice(contactToRemove, 1);

    const contactsAfterRemove = contacts.slice();

    setContacts(contactsAfterRemove);

    // 1 contact, show single chat screen associated
    if (contactsAfterRemove.length === 1) {
      const contact = contactsAfterRemove[0];
      const conversationId = Conversation.getOrCreate({
        phoneNumber: contact.getMainPhoneNumber(),
        contactToLinkIfCreate: contact,
      }).conversation.id;

      setIsConversationStarted(false);
      setSelectedConversationId(conversationId);
      setShowConversation(true);
    } else {
      setShowConversation(false);
    }

    setAddContactInputDefaultValue(currentAddContactInputValue || "");
  };

  const [searchConversationQuery, setSearchConversationQuery] = useState("");
  const [searchContactQuery, setSearchContactQuery] = useState("");

  const handleSelectedConversation = (
    conversation: Conversation,
    selected: boolean
  ) => {
    setSelectedConversations((prevSelectedConversations) => {
      const isSelected = prevSelectedConversations.some(
        (selectedConversation) => selectedConversation.id === conversation.id
      );

      if (isSelected) {
        return prevSelectedConversations.filter(
          (selectedConversation) => selectedConversation.id !== conversation.id
        );
      } else {
        return [...prevSelectedConversations, conversation];
      }
    });

    if (selected) {
      setSelectedConversationsCheckboxes([
        ...selectedConversationsCheckboxes,
        conversation.id,
      ]);
    } else {
      setSelectedConversationsCheckboxes(
        selectedConversationsCheckboxes.filter(
          (item) => item !== conversation.id
        )
      );
    }
  };

  const conversationsRendered = displayedConversations(
    conversations,
    searchConversationQuery,
    handleShowConversation,
    isEditing,
    selectedConversationsCheckboxes,
    handleSelectedConversation
  );

  let selectedAllConversationCheckboxes = false;
  if (searchConversationQuery.length > 0) {
    if (selectedConversations.length === conversationsRendered.length) {
      selectedAllConversationCheckboxes = true;
    } else {
      selectedAllConversationCheckboxes = false;
    }
  } else {
    if (
      selectedConversationsCheckboxes.length === conversationIdsArray.length
    ) {
      selectedAllConversationCheckboxes = true;
    } else {
      selectedAllConversationCheckboxes = false;
    }
  }

  const selectAllConversations = () => {
    if (selectedConversations.length === conversationsRendered.length) {
      setSelectedConversations([]);
    } else {
      const filteredConversations = conversationsRendered.map(
        (renderedConversation) => renderedConversation.props.conversation
      );
      setSelectedConversations(filteredConversations);
    }

    if (
      selectedConversationsCheckboxes.length === conversationIdsArray.length
    ) {
      setSelectedConversationsCheckboxes([]);
    } else {
      setSelectedConversationsCheckboxes(conversationIdsArray);
    }
  };

  // Conversation can be opened from many different places (like contact info), by setting the selectedConversationId
  // In this case we dont go over the create conversation flow but instead directly open it
  // only on first render
  useLayoutEffect(() => {
    if (selectedConversationId) {
      handleShowConversation();
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleOverlay = () => {
    if (selectedConversations.length > 0) {
      setOverlayOpen(!overlayOpen);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    }
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedConversations]);

  const handleDeleteSelectedConversations = async () => {
    const toastId = `deleteConversations-${selectedConversations[0].id}`;
    toast.loading(`Deleting conversations...`, {
      id: toastId,
    });

    const result = (
      await Promise.all(selectedConversations.map(deleteConversation))
    ).every(Boolean);

    if (result) {
      toast.success(
        `Conversation${selectedConversations.length > 1 ? "s" : ""} deleted successfully`,
        {
          id: toastId,
        }
      );
    } else {
      toast.error(
        `Error deleting ${
          selectedConversations.length == 1
            ? "the conversation"
            : "some of the conversations"
        }, please try again later.`,
        {
          id: toastId,
        }
      );
    }

    handleCloseEditing();
    toggleOverlay();
  };

  const handleCloseEditing = () => {
    setSelectedConversations([]);
    setIsEditing(false);
    setSelectedConversationsCheckboxes([]);
  };

  const startNewConversation = () => {
    setContacts([]);
    setShowStartGroupConversation(false);
    setSelectedConversationId(null);
    setShowLoadingPhoneIcon(false);
    setIsConversationStarted(false);
    setShowConversation(false);
  };

  // Special case when a conversation has been started, the user clicks on message menu icon again (no selected conversation), we should restart the flow for create conversation
  if (isConversationStarted && !selectedConversationId) {
    startNewConversation();
  }

  const onConversationStarted = (conversationId: string) => {
    setIsConversationStarted(true);
    setSelectedConversationId(conversationId);
    setShowConversation(true);
  };

  const handleShowContactPicker = (show: boolean) => {
    setShowContactPicker(show);
    setDisplayContact(null);
  };

  const contactSelectedHandler = (searchQuery: string | WebGwContact) => {
    const contact = savedContacts?.findWithNumber(
      typeof searchQuery === "string"
        ? searchQuery
        : searchQuery.getMainPhoneNumberWithCaps() ||
            searchQuery.getMainPhoneNumber()
    );

    if (!contact) {
      return;
    }

    handleAddNumber(contact?.getMainPhoneNumber());
  };

  const setConversationUpgradingToGroupChat = useSetAtom(
    atoms.messaging.conversationUpgradingToGroupChat
  );

  const setShowCreateGroupChat = (show: boolean) => {
    if (!show) {
      setConversationUpgradingToGroupChat((prev) => {
        if (prev) {
          setShowConversation(true);
        }
        return null;
      });
      setIsConversationStarted(true);
      setContacts([]);
      return;
    }

    setIsConversationStarted(false);

    // When upgrading a conversation from 1-1 to group, we need to set the contacts in order to have it preselected for the picker and in case we go back to conversation started mode
    if (selectedConversationId) {
      const conversation = conversations.get(selectedConversationId);

      setContacts(conversation?.participants || []);
      setConversationUpgradingToGroupChat(conversation);
    }
  };

  const toggleShowInvitePopup = () => {
    setIsShowInvitePopup({ invite: !isShowInvitePopup?.invite });
  };

  return (
    <>
      {conversationsRendered.length > 0 || searchConversationQuery ? (
        <Box>
          <SectionHeader
            pageName="Conversations"
            onClick={() =>
              showConversation
                ? startNewConversation()
                : [startNewConversation(), setNewConversation(true)]
            }
            iconButton={<AddCommentIcon />}
          >
            {!isEditing && (
              <div css={{ position: "relative", display: "inline-block" }}>
                <IconButtonBox ref={dropdownRef} onClick={toggleDropdown}>
                  <MoreVertIcon css={{ color: colors.primaryTextColor }} />
                  {isDropdownOpen && (
                    <DropDownContainer
                      css={[dropdownContainer, headerDropdown]}
                    >
                      <DropDownButton
                        css={sectionHeaderDropdownItemStyle}
                        onClick={() => setIsEditing(true)}
                      >
                        <EditIcon /> Edit
                      </DropDownButton>
                    </DropDownContainer>
                  )}
                </IconButtonBox>
              </div>
            )}
          </SectionHeader>
          {!isEditing && (
            <SearchBar
              searchQuery={searchConversationQuery}
              setSearchQuery={setSearchConversationQuery}
            />
          )}
          {isEditing && (
            <EditConvoMenu>
              <ConvoMenuSections>
                <label>
                  <Checkbox
                    checked={selectedAllConversationCheckboxes}
                    partiallyChecked={selectedConversations.length > 0}
                    onChange={selectAllConversations}
                    css={{ marginRight: "1em" }}
                  />
                  {selectedAllConversationCheckboxes ? "All " : ""}
                  {selectedConversations.length} selected
                </label>
              </ConvoMenuSections>

              <ConvoMenuSections>
                {/* //TODO UNCOMMENT WHEN MARK UNREAD IS IMPLEMENTED */}
                {/* <ConvoMenuItem
                css={{
                  borderRight: "1px solid",
                  borderColor: colors.secondaryTextColor,
                  paddingRight: "1em",
                  color:
                    selectedConversations.length > 0
                      ? colors.primaryTextColor
                      : colors.secondaryTextColor,
                }}
              >
                <MessageOutlinedIcon />
                Mark as Unread
              </ConvoMenuItem> */}
                <ConvoMenuItem
                  css={{
                    borderRight: "1px solid",
                    borderColor: colors.secondaryTextColor,
                    paddingRight: "1em",
                    color:
                      selectedConversations.length > 0
                        ? colors.primaryTextColor
                        : colors.secondaryTextColor,
                    cursor:
                      selectedConversations.length > 0 ? "pointer" : "auto",
                  }}
                  onClick={toggleOverlay}
                >
                  <DeleteIcon />
                  Delete
                </ConvoMenuItem>
                <ConvoMenuItem
                  css={{ color: colors.primaryAccentColor, cursor: "pointer" }}
                  onClick={handleCloseEditing}
                >
                  <CloseIcon />
                  Close
                </ConvoMenuItem>
              </ConvoMenuSections>
            </EditConvoMenu>
          )}

          <AnimatePresence>
            {overlayOpen && (
              <ConfirmationPopup
                togglePopup={toggleOverlay}
                title="Delete Conversation"
                confirmationMessage="Are you sure you want to delete these conversations?"
                handleAction={handleDeleteSelectedConversations}
              />
            )}
          </AnimatePresence>

          <Suspense fallback={<ThreeLinesLoaderBox />}>
            <PreloadConversationDeps />
            <ConversationsList
              searchConversationQuery={searchConversationQuery}
            >
              {conversationsRendered}
            </ConversationsList>
          </Suspense>
        </Box>
      ) : (
        <Box>
          {conversationsLoading ? (
            <ThreeLinesLoaderBox />
          ) : (
            <>
              <SectionHeader pageName="Conversations" />
              <div
                css={{
                  color: colors.secondaryTextColor,
                  fontSize: "1.5rem",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "2em",
                  alignItems: "center",
                }}
              >
                <MessageOutlinedIcon css={{ fontSize: "4em" }} />
                <div>No Conversations</div>
              </div>
            </>
          )}
        </Box>
      )}

      {showConversation ? (
        /* changing the key resets the component's state. This closes the chat info if it's opened */
        <ChatScreen
          key={selectedConversationId}
          closeChat={startNewConversation}
          onRemoveContact={handleRemoveContact}
          isConversationStarted={isConversationStarted}
          contacts={contacts}
          contactSelectHandler={contactSelectedHandler}
          setShowCreateGroupChat={setShowCreateGroupChat}
          onConversationStarted={onConversationStarted}
          onShowContactPicker={handleShowContactPicker}
          onAddNumber={handleAddNumber}
          showLoadingPhoneIcon={showLoadingPhoneIcon}
          searchContactQuery={searchContactQuery}
          setSearchContactQuery={setSearchContactQuery}
          addContactInputDefaultValue={addContactInputDefaultValue}
        />
      ) : (
        <NewConversationScreen
          onConversationStarted={onConversationStarted}
          contacts={contacts}
          onRemoveContact={handleRemoveContact}
          contactSelectHandler={contactSelectedHandler}
          onShowContactPicker={handleShowContactPicker}
          onAddNumber={handleAddNumber}
          showLoadingPhoneIcon={showLoadingPhoneIcon}
          searchContactQuery={searchContactQuery}
          setSearchContactQuery={setSearchContactQuery}
          showStartGroupConversation={showStartGroupConversation}
          onShowStartGroupConversation={handleShowStartGroupConversation}
          newConversation={newConversation}
          setShowCreateGroupChat={setShowCreateGroupChat}
          addContactInputDefaultValue={addContactInputDefaultValue}
        />
      )}
      {showContactPicker && (
        <ContactPicker
          from={"Conversation"}
          preSelectedContacts={contacts}
          onSelectedContacts={handleSelectedContacts}
          onClose={() => handleShowContactPicker(false)}
        />
      )}
      {isShowInvitePopup && isShowInvitePopup.invite && (
        <InvitePopup
          togglePopup={toggleShowInvitePopup}
          number={isShowInvitePopup.number}
        />
      )}
    </>
  );
}

function displayedConversations(
  conversations: ConversationMap,
  searchQuery: string,
  onShowConversation: () => void,
  isEditing: boolean,
  selectedConversationsCheckboxes,
  onSelectedConversation
) {
  let nbGroupChatsWithNoName = 0;

  const getUnnamedGroupChatPosition = (conversation: Conversation) => {
    return conversation.getIsGroupChat() && !conversation.getName()
      ? ++nbGroupChatsWithNoName
      : undefined;
  };

  return (
    conversations
      .entries()
      // TODO: Change conversationId to the actual group chat id
      .reduce(
        (acc, [conversationId, conversation]) => {
          // Don't display up empty conversations or with no contacts
          if (
            conversation.participants.length === 0 ||
            conversation.getMessages().length === 0
          ) {
            return acc;
          }

          const lastInMessage = conversation.getLastInMessage();
          const lastMessage = conversation.getLastMessage();

          let searchIndex: number | undefined;
          const firstContact = conversation.participants[0];

          searchQuery = searchQuery.trim();
          if (searchQuery.length) {
            searchQuery = searchQuery.toLowerCase();

            if (conversation.getIsGroupChat()) {
              searchIndex = lastMessage
                ?.getText()
                ?.toLowerCase()
                .indexOf(searchQuery);

              if (searchIndex === -1) {
                searchIndex = undefined;
              }

              if (searchIndex === undefined) {
                searchIndex = conversation
                  .getName(true)!
                  .toLocaleLowerCase()
                  .indexOf(searchQuery);

                if (searchIndex === -1) {
                  searchIndex = undefined;
                }
              }

              if (
                searchIndex === undefined &&
                !conversation.filterConversation(searchQuery)
              ) {
                return acc;
              }
            } else {
              searchIndex = lastMessage
                ?.getText()
                ?.toLowerCase()
                .indexOf(searchQuery);

              if (searchIndex === -1) {
                searchIndex = undefined;
              }

              if (
                searchIndex === undefined &&
                !firstContact.filterContact(
                  searchQuery,
                  ...(firstContact.isChatbot
                    ? [FilterTypeFields.NAME, FilterTypeFields.EMAIL]
                    : [FilterTypeFields.ALL])
                )
              ) {
                return acc;
              }
            }
          }

          if (!lastMessage) return acc;

          acc.push(
            <ConversationItem
              key={conversationId}
              conversation={conversation}
              enableChatScreen={onShowConversation}
              lastInMessage={lastInMessage}
              lastMessage={lastMessage}
              contact={firstContact} // TODO: make this a contact array for group chats ?
              isEditing={isEditing}
              noNameGroupChatPosition={getUnnamedGroupChatPosition(
                conversation
              )}
              selectedConversationsCheckboxes={selectedConversationsCheckboxes}
              onSelectedConversation={onSelectedConversation}
            />
          );
          return acc;
        },
        [] as ReactElement<React.ComponentProps<typeof ConversationItem>>[]
      )
      .sort(
        (
          { props: { conversation: aConversation } },
          { props: { conversation: bConversation } }
        ) =>
          bConversation.getLastMessageTimeMs() -
          aConversation.getLastMessageTimeMs()
      )
  );
}

export enum AddNumberResult {
  PHONE_NOT_VALID,
  ALREADY_ADDED,
  CANNOT_ADD_MYSELF,
  CHECKING_CAPS,
  NO_CAPS,
  SUCCESS,
}
